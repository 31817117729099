.animated-text h3{
  color: #541936 ;
}
.video-responsive iframe{
    
    width: 80%;
    height: 80vh;
}

@media (max-width: 414px) {
    .video-responsive iframe {
        width: 80%;
        height: 30vh;
    }
      
    }
    
  
  .video-responsive iframe {
    border-radius: 10px; /* Adjust the radius to your preference */
  }
  .youtube-p{
    text-align: start;
  }
  .youtube-h1{
    text-align: start;
  }
  .video-container {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow */
    padding: 20px; /* Adjust padding as needed */
  }
  
  .animated-text-item {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Add text shadow */
  }
  
  @media (max-width: 768px) {
    .animated-text-item {
      font-size: 16px; /* Adjust font size for smaller screens */
    }
  }
  
  .heading {
    color: #555555;
    text-align: left;
    padding: 10px; /* Adjust padding as needed */
  }
  
  .department-heading {
    color: #541936;
  }
  
  .department-info {
    padding: 10px; /* Adjust padding as needed */
  }
  .video-container {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow */
    padding: 20px; /* Adjust padding as needed */
  }
  
  .animated-text-item {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Add text shadow */
  }
  
  @media (max-width: 768px) {
    .animated-text-item {
      font-size: 16px; /* Adjust font size for smaller screens */
    }
  }
  
  .heading {
    color: #555555;
    text-align: left;
    padding: 10px; /* Adjust padding as needed */
  }
  
  .department-heading {
    color: #541936;
  }
  
  .department-info {
    padding: 10px; /* Adjust padding as needed */
  }
  .yellow-line-y {
   margin: 0 auto;
    height: 3px;
    border-radius: 10px;
    width: 100px; /* Adjust width as needed */
    background-color: #f4b959;
    margin: 1 auto;
    border-width: 2px 0 0;
    border-color: #f4b959;
}
  
.yellow-line-AT {
 
   height: 3px;
   border-radius: 10px;
   width: 70px; /* Adjust width as needed */
   background-color: #f4b959;
   margin: 1 auto;
   border-width: 2px 0 0;
   border-color: #f4b959;
}
.video-responsive {
  /* Your existing styles */
  opacity: 0; /* Initially hide the element */
  transform: translateY(100px); /* Move the element down initially */
  transition: opacity 1s ease, transform 1s ease; /* Add transition effect */
}

.video-responsive.animated {
  opacity: 1; /* Show the element when in view */
  transform: translateY(0); /* Move the element back to its original position */
}

/* Add slide animation */
.video-responsive.slide-in {
  transform: translateY(-100px);
}

.video-responsive.animated.slide-in {
  transform: translateY(0);
}
