/* StatBox.css */
.stat-box {
    display: flex;
    justify-content: space-between;
    background-color: #FF8C00;
    padding: 20px;
    
  }
  
  .stat-box .left,
  .stat-box .center,
  .stat-box .right {
    text-align: center;
  
    color: white;
    flex: 1;
  }
  
  .stat-box span {
    display: block;
    color: white;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  
  .stat-box span:last-child {
    font-size: 14px;
  }
