.app-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
  }
  
  .card {
    width: 25%;
    min-width: 200px;
    padding: 40px; /* Increase padding for better appearance */
    text-align: center;
    border: none; /* Remove default border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
    transition: box-shadow 0.3s ease; /* Smooth transition for shadow */
    border-radius: 10px; /* Optional: add border radius for rounded corners */
    background-color: #fff; /* Optional: add background color */
  }
  
  .card:hover {
    box-shadow: 0 8px 30px rgba(198, 12, 12, 0.55); /* Shadow effect on hover */
  }
  
  .card img {
    width: 80%;
    max-height: 100px;
    /* margin-bottom: 10px; */
  }
  
  .rating {
    font-size: 20px;
    font-weight: bold;
    color: #530937;
  }
  
  .count {
    font-size: 14px;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .card {
      width: 50%;
    }
  }
  
  @media (max-width: 480px) {
    .card {
      width: 100%;
    }
  }
  