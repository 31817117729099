.content-Tb h1{
    color: #603258;
    font-size: 16px;
   
}
.content-Tb p{
    color: #333333;
    font-size: 14px;
   
}
.carousel-image {
    height: 3px; /* Adjust the height as per your requirement */
    width: 100%;
    object-fit: cover; /* Maintains aspect ratio */
  }
  