/* Existing styles */

.banner {
  background-size: cover;
  background-position: center;
  height: 560px; /* Aap apni zarurat ke anusar height set kar sakte hain */
  display: flex;
  align-items: center;
  color: white;
}

.banner-content-B h1 {
  font-family: 'Euclid Circular A';
  color: rgb(255, 255, 255);
  font-size: 2rem;
  text-align: left;
  animation: slideRightToLeft 1s ease forwards; /* Animation ko apply karein */
}

.banner-content-B p {
  animation: bounce 3s ease forwards;
}

.banner-content-B button {
  display: flex;
  font-family: 'Euclid Circular A';
  background-color: #530937;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.banner-content-B button:hover {
  background-color: #ff8000;
  transform: scale(1.1);
}

/* New styles for removing underline from the link */
.no-underline {
  text-decoration: none;
}

@keyframes bounce {
  from {
    opacity: 0;
    transform: translateY(500px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.banner {
  animation: slide-in 1s ease forwards;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(-200px); /* Slide ko upar se neeche */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Slide karke neeche aaye */
  }
}

@keyframes slideRightToLeft {
  from {
    transform: translateX(200%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
