/* src/BlogPage.css */
.blog-page {
    padding: 20px;
    background-color: #f8f8f8;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .blogs-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .blog {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .blog:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .blog-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  
  .blog-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
  }
  
  .blog:hover .blog-image {
    transform: scale(1.1);
  }
  
  .blog-content {
    padding: 15px;
  }
  
  .blog-title {
    font-size: 1.5em;
    margin: 10px 0;
    color: #333;
  }
  
  .blog-description {
    color: #666;
    font-size: 1em;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .blogs-container {
      grid-template-columns: 1fr;
    }
  }
  
  /* Animation CSS */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(1000px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .blog.animate {
    opacity: 1;
    animation: fadeIn 1.5s ease-out;
  }
  