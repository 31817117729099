.wpb_wrapper h1{
    font-family: 'Euclid Circular A';
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    color: #541936;
    
}
.wpb_wrapper h2{
    font-family: 'Euclid Circular A';
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    color: #541936;
    
}
.wpb_wrapper p{
   
    font-family: 'Euclid Circular A';
    font-size: 15px;
    color: #333333;
    
}

