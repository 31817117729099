.contact-form-map-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact-form-container, .map-container {
  flex: 1 1 45%;
  min-width: 300px;
  margin: 10px;
}

.contact-form-container {
  padding: 20px;
  border-radius: 10px;
  background-color: rgb(232, 207, 207);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.contact-form-container .form-group {
  display: flex;
  flex-wrap: wrap;
}

.contact-form-container .form-group div {
  flex: 1;
  min-width: calc(50% - 20px);
}

.contact-form-container label {
  display: flex;
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
}

.contact-form-container input[type="text"],
.contact-form-container input[type="email"],
.contact-form-container input[type="date"],
.contact-form-container input[type="tel"],
.contact-form-container textarea,
.react-datepicker-wrapper,
.datepicker {
  display: block;
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 30px;
  box-sizing: border-box;
  font-size: 16px;
}

.contact-form-container textarea {
  height: 100px;
}

.contact-form-container .error {
  color:black;
  margin-top: -10px;
  margin-bottom: 10px;
}

.contact-form-container button {
  padding: 10px 45px;
  background-color: rgb(96, 50, 88);;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 18px;
  display: block;
  margin: 0 auto;
}

.contact-form-container button:hover {
  background-color: #dc7c07;
}

.contact-form-container .success {
  color: #4caf50;
  font-weight: bold;
  text-align: center;
}

.contact-form-container .spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.map-container {
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  height: 500px;
}

/* CSS for Contact Form for Mobile Devices */
@media (max-width: 768px) {
  .contact-form-container input[type="date"]{
    width: 350px;
    
  }
  .contact-form-container .form-group {
    flex-direction: column;
  }

  .contact-form-container .form-group div {
    width: 100%;
  }
  

  .contact-form-container label {
    margin-bottom: 5px;
  }

  .contact-form-container input[type="text"],
  .contact-form-container input[type="email"],
  
  .contact-form-container input[type="tel"],
  .contact-form-container textarea {
    
    width: 100%;
    margin-bottom: 10px;
    
  }
}

.contact-form-container {
  opacity: 0;
  transform: translateY(200px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.contact-form-container.animate {
  opacity: 1;
  transform: translateY(0);
}
.map-container {
  opacity: 0;
  transform: translateY(300px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.map-container.animate {
  opacity: 1;
  transform: translateY(0);
}

/* For screens up to 375px width (iPhone SE) */
@media (max-width: 375px) {
  .contact-form-container {
    padding: 10px; /* Add some padding for better spacing */
  }

  .contact-form-container input[type="date"] {
    width: 100%; /* Use full width */
  }
  
  .contact-form-container .form-group {
    flex-direction: column;
  }

  .contact-form-container .form-group div {
    width: 100%; /* Use full width */
  }

  .contact-form-container label {
    margin-bottom: 5px;
    font-size: 14px; /* Adjust font size for readability */
  }

  .contact-form-container input[type="text"],
  .contact-form-container input[type="email"],
  .contact-form-container input[type="tel"],
  .contact-form-container textarea {
    width: 100%; /* Use full width */
    margin-bottom: 10px;
    font-size: 16px; /* Adjust font size for readability */
  }

  .contact-form-container textarea {
    height: 100px; /* Ensure textarea has a usable height */
  }
}

/* For screens up to 768px width */
@media (max-width: 768px) {
  .contact-form-container input[type="date"] {
    width: 100%; /* Use full width */
  }
  
  .contact-form-container .form-group {
    flex-direction: column;
  }

  .contact-form-container .form-group div {
    width: 100%; /* Use full width */
  }

  .contact-form-container label {
    margin-bottom: 5px;
  }

  .contact-form-container input[type="text"],
  .contact-form-container input[type="email"],
  .contact-form-container input[type="tel"],
  .contact-form-container textarea {
    width: 100%; /* Use full width */
    margin-bottom: 10px;
  }
}
